/* font imports, generated by fontsquirrel.com */
@font-face {
    font-family: 'source_sans_proregular';
    src:
        url('webfonts/project-webfonts/sourcesanspro-regular-webfont.woff2')
            format('woff2'),
        url('webfonts/project-webfonts/sourcesanspro-regular-webfont.woff')
            format('woff');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'playfair_displayregular';
    src:
        url('webfonts/project-webfonts/playfairdisplay-regular-webfont.woff2')
            format('woff2'),
        url('webfonts/project-webfonts/playfairdisplay-regular-webfont.woff')
            format('woff');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'baskervvilleregular';
    src:
        url('webfonts/project-webfonts/baskervville-regular-webfont.woff2')
            format('woff2'),
        url('webfonts/project-webfonts/baskervville-regular-webfont.woff')
            format('woff');
    font-weight: normal;
    font-style: normal;
}

/*Remove default margin*/
body {
    background-color: #fafafa;
    margin: 0;
    max-width: 100%;
    padding: 0;
    min-height: 100vh;
    /*Allow for the body's height to change dynamically*/
}
* {
    -webkit-appearance: none;
    appearance: none;
    background: none;
}
input[type='search'] {
    -webkit-appearance: none;
    appearance: none;
}
button,
input,
select,
textarea {
    font-family: 'playfair_displayregular', sans-serif;
    font-size: 100%;
    width: 100%;
    box-sizing: border-box;
    background-color: #d84ad8;
}
/*+++++++++++++++++++++++++++++| GENERAL SETUP |+++++++++++++++++++++++++++++++++*/

html {
    font-family: 'source_sans_proregular', helvetica, sans-serif;
    box-sizing: border-box;
    height: 100%;
}
*,
*::before,
*::after {
    box-sizing: inherit;
}
footer {
    background-color: #d84ad8;
}
main,
header {
    display: block;
}
div {
    display: block;
}
form {
    display: block;
}
input[type='search'] {
    -webkit-appearance: none;
    appearance: none;
    border: none;
}
textarea[type='text'] {
    margin: 0 0 15px 0;
    border-radius: 3px;
    border: 0;
    height: 60px;
    padding-left: 10px;
}
textarea[type='text']:focus {
    background: rgb(216, 74, 216, 40%);
}
button[type='submit'] {
    width: 30%;
    border: none;
    background-color: rgb(0, 164, 0, 60%);
    color: aliceblue;
    border-radius: 6px;
}
button[type='submit']:hover {
    background-color: #00a400;
}

/*++++++++++++++++++++++++|TYPOGRAPHY |+++++++++++++++++++++++++++++*/

h1,
h2,
h3,
h4,
h5,
h6 {
    font-weight: normal;
    font-family: 'playfair_displayregular', sans-serif;
}
p {
    font-size: 0.625rem;
    line-height: 1;
    letter-spacing: 0.115rem;
    word-spacing: 0.2rem;
    font-weight: 600;
    font-family: 'source_sans_proregular', sans-serif;
}
nav {
    width: 100%;
}
ul {
    padding-left: 0;
    list-style-type: none;
}
li {
    display: list-item;
}
a {
    text-decoration: none;
    background: transparent;
}
/*



*/

/*=======================================================| FLIGHT HEADER MENU |====================================================*/

.website-banner {
    background: #ffff;
    position: fixed;
    width: 100%;
    height: 5.313rem;
    top: 0;
}
.fixed-banner-wrap {
    position: relative;
    display: flex;
    align-items: center;
    overflow: hidden;
    height: 100%;

    & .title-wrap {
        width: 100%;
        height: 100%;
        background: url('img/Header-Logo/IWAS-Logo-50w.png') no-repeat 16.5%/12.5%;

        & .h1-title {
            text-decoration: none;
            font-size: 12px;
            height: 30%;
            position: relative;
            left: 29%;
            top: 10%;
        }
        & .tagline {
            font-size: 6px;
            text-decoration: none;
            margin: -25px 25px 0 25px;
            letter-spacing: normal;
            word-spacing: normal;
            position: relative;
            left: 28%;
            top: 20%;
        }
    }
}
/*Menu button from Font Awesome for navigation*/
.fa-bars {
    cursor: pointer;
    text-align: center;
    margin-left: 10px;
    color: #ffff;
}
.fa-bars::after {
    content: ' ';
    clear: both;
    display: table;
}

/*Remove the title name, social media icons and search bar from the header with screen 780px and less (Phone screen)*/
.search-social,
.meta-link,
.banner-wrapper-logo {
    display: none;
}

/*=================================================================| FLIGHT NAVIGATION MENU |===============================================*/

.nav-wrap {
    margin-top: 100px;
}
.hamburgerMenu {
    position: fixed;
    top: 2em;

    & i {
        color: black;
    }
}
.header-nav {
    display: flex;
    flex-direction: row;
    position: fixed;
    top: 0;
    left: 0px;
    z-index: 5;
    transition: 0.2s;
    width: 0px;
    overflow: hidden;
    height: 100%;
    overflow-y: scroll;
    scrollbar-width: none;
    -ms-overflow-style: none;
}
.active {
    width: 80%;
    background-color: #cfcfc5;

    .arrow {
        width: 5%;
        position: relative;
        top: 0.19em;
        right: 1em;
        float: right;
        cursor: pointer;
    }
}

.nav-list {
    margin: 0;
    padding-top: 1.56em;
    width: 25em;
    white-space: nowrap;
    background-color: #cfcfc5;
    & li {
        padding: 10px 0 10px 10px;
        & a {
            font-size: clamp(1em, 1vw, 1em);
            text-transform: none;
        }
    }
}
.dropdown-list {
    display: none;
    background-color: #d84ad8;
    width: 70%;
    text-align: justify;
    & li {
        display: table;
        width: 100%;

        & a {
            display: block;

            font-size: clamp(0.7em, 1vw, 1em);
            color: aliceblue;
            filter: brightness(1.5);
        }
    }
}
.show {
    display: block;
}
.dropdown-list > li:hover {
    background-color: #8000ff8f;
}
.navClose {
    width: 10%;
    height: 100%;
    padding-top: 1.56em;
}
#nav-times {
    font-size: 1.2em;
    color: #000000;
    margin-right: 0.8em;
}
#nav-times::after {
    content: ' ';
    clear: both;
    display: table;
}
/*Remove the navigation dropdown list from Menu*/

/*FOR SCREEN SIZE min-width: 26.25em: REMOVE TRANSITION SECTION*/
.main-body {
    padding: 0;
    min-height: 80vh;
}

/*=================================================================| FLIGHT MAIN MENU |========================================================*/

/*===============================================================| MAIN - WRAPPER SECTION |================================================*/
/*FOR max-width of 420px*/
/*PROMO-GRID SECTION*/

.column-200,
.column-250 {
    display: none;
}
.column-400 {
    display: flex;
    flex-direction: column;
    width: 100%;
    & .intro_title {
        font-size: 1em;
        text-align: center;
        line-height: normal;
        border: 0;
        margin: 0;
        padding: 0;
        outline: 0;
    }
    & .promo-list-item {
        display: flex;
        flex-direction: column;
        width: 90%;
        align-self: center;
        & .card1-promoContent,
        .card2-promoContent,
        .card3-promoContent {
            flex: 1;
            display: grid;
            grid-template-rows: 2fr 3fr;
            grid-template-areas: 'image_loader_wrapper' 'text_loader_wrapper';
            margin: 15px;
            background: rgba(139, 0, 139, 0.65);
            border-radius: 10px;
            box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.28);
        }
    }
    .GalleryApp {
        border-top-style: solid;
        border-top-color: #00a400;
        margin: 2em;
        width: 80%;
        text-align: center;

        &.GalleryApp > iframe {
            height: 60%;
        }

        #GalleryApp {
            background-color: rgb(139, 0, 139, 70%);
            text-align: left;
            margin: 0.5em 0 2em 0;
            box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.28);
            width: 100%;
            & h1 {
                margin: 0;
                padding: 0.3em;
                font-size: clamp(0.1em, 3vw, 2em);
                color: aliceblue;
            }
        }
    }
}
.wrapper-main-cycle {
    display: none;
}
.list_items:nth-child(1),
.list_items:nth-child(2),
.list_items:nth-child(3),
.list_items:nth-child(4),
.list_items:nth-child(5),
.list_items:nth-child(6),
.list_items:nth-child(7),
.list_items:nth-child(8) {
    float: none;
    width: auto;
}
.main-wrapper-1 {
    display: flex;
    width: 90%;
    padding: 0 0.625rem;
    margin-right: auto;
    margin-left: auto;
}
.main-wrapper-1::after {
    clear: both;
    content: '';
    display: table;
}

.into_title {
    margin-bottom: 0.938rem;
}

.image_loader_wrapper,
.card2-promoContent .image_loader_wrapper,
.card3-promoContent .image_loader_wrapper {
    grid-area: image_loader_wrapper;
    background-image: url('img/image wrapper/IMG-20171124-WA0003.jpg');
    background-size: cover;
    background-position: center;
    margin: 1em 1em 0 1em;
}
.card1-promoContent .text_loader_wrapper,
.card2-promoContent .text_loader_wrapper,
.card3-promoContent .text_loader_wrapper {
    grid-area: text_loader_wrapper;
    margin: 0.5em 1em 1em 1em;
}
.text_title h3 {
    margin-top: 0;
}
.h3-title a {
    color: aliceblue;
    filter: brightness(1.5);
    font-size: clamp(0.1em, 3vw, 2em);
}
.text_teaser > p {
    color: aliceblue;
    filter: brightness(1.5);
    font-size: clamp(0.1em, 3vw, 2em);
}
.text_link a:hover {
    background-color: #00a400;
    color: black;
    filter: brightness(1.5);
    border-radius: 4px;
    padding: 2px 4px 2px 4px;
}
.msgForm {
    display: block;
    margin: 10px 0;
    width: 98%;

    .msgInput {
        margin: 0 0 15px 0;
        border-radius: 3px;
        border: 0;
        height: 30px;
        padding: 10px;
    }
}
.msgInput:focus {
    background: rgb(216, 74, 216, 40%);
    border-radius: 5px;
}

/*++++++++++++++++++++++++++++++++++++++++++++++++++++++++++| FLIGHT FOOTER MENU |++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/

#footer-location,
.footer-logo,
.footer-info > h3,
.col-1-list,
.txt-undl,
.footer-address > address > p,
.footer-contact {
    display: none;
}

.col-2-list,
.small-screen-legal {
    display: none;
}
.footer-wraper {
    background-color: #d84ad8;

    .fixed-wrap {
        width: 80%;
        margin: 0 auto;
        background-color: #d84ad8;
    }
    & .footer-network-links {
        display: flex;
        justify-content: center;
    }
    & .small-screen-social {
        font-family: 'baskervvilleregular', source_sans_proregular;
        text-align: center;
        margin: 0 0 0 0;
        padding: 1em 0 0 0;
        font-size: clamp(1.3em, 3vw, 2em);
    }
    & .footer-address {
        grid-column: 2;
        grid-row: 1;
        margin-left: 10px;
        & h3 {
            margin-bottom: 2em;
        }
        & a {
            font-family: 'baskervvilleregular', source_sans_proregular;
        }
    }
    & #footer-location {
        grid-column: 2;
        grid-row: 2;
        margin-bottom: 2em;
    }
}

.footer-network-links .social-facebook {
    background: url('img/Header-socialPhoto/icons8-facebook-48.png') no-repeat
        20%;
    text-indent: -9999px;
    display: inline-block;
    width: 30px;
    margin-left: 5px;
}
.footer-network-links .social-twitter {
    background: url('img/Header-socialPhoto/icons8-twitter-48.png') no-repeat
        20%;
    text-indent: -9999px;
    display: inline-block;
    width: 30px;
    margin-left: 5px;
}
.footer-network-links .social-instagram {
    background: url('img/Header-socialPhoto/icons8-instagram-48.png') no-repeat
        20%;
    text-indent: -9999px;
    display: inline-block;
    width: 30px;
    margin-left: 5px;
}
.footer-network-links .social-youtube {
    background: url('img/Header-socialPhoto/icons8-play-button-48.png')
        no-repeat 20%;
    text-indent: -9999px;
    display: inline-block;
    width: 30px;
    margin-left: 5px;
}
.subfooter-fixed-wrap {
    background-color: deeppink;
    .footer-legal-terms {
        font-family: 'baskervvilleregular', source_sans_proregular;
        margin: 0;
        text-align: center;
    }
}

/*++++++++++++++++++++++++++++++++++++++++++++++++++++++++| HEADER - Desktop view |+++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
/*Using @media query to create the responsive design effect for screen size 26.25em and larger (Desktop screen)*/
@media screen and (width > 35em) {
    .menu-icon,
    #nav-close,
    .fa-bars {
        display: none;
    }
    .website-banner {
        background-image: url('img/image wrapper/caidrro-_52iqVQKEsU-unsplash.jpg');
        background-position: 68% 71%;
        background-size: cover;
        background-color: unset;
        background-repeat: no-repeat;
        position: relative;
        z-index: 10;
        height: 210px;
        width: 100%;
        padding: 0.5% 0;
    }
    .fixed-banner-wrap {
        display: grid;
        grid-template-columns: repeat(6, minmax(0, 1fr));
        width: 62.01%;
        height: 100%;
        margin: 0 auto;
        padding: 0;
        position: static;
        overflow: unset;
        .title-wrap {
            display: block;
            grid-column: 2/5;
            height: auto;
            background-image: none;
            padding-left: 2.5%;
            background-position: unset;
        }
        .title-wrap .h1-title {
            text-decoration: none;
            font-size: clamp(2.5em, 2.5vw, 3.5em);
            font-style: oblique;
            width: 100%;
            float: none;
            margin: 0;
            height: auto;
            position: static;
        }
        .title-wrap .h1-title a {
            color: aliceblue;
            filter: brightness(1.5);
        }
        .title-wrap .tagline {
            text-align: right;
            margin: -2px 0 0 50px; /*use negative px value to create overflow of borders*/
            font-size: clamp(0.3em, 0.8vw, 1em);
            margin: 0;
            width: 100%;
            position: static;
        }
    }
    .banner-wrapper-logo {
        display: block;
        grid-column: 1/2;
    }
    .banner-wrapper-logo > img {
        float: right;
        max-width: 100%;
    }
    .search-social {
        display: block;
        grid-column: 5/7;
        margin-left: 10.25%;
        margin-bottom: 6%;

        .srch-frm {
            width: 80%;
            .srch-bar {
                display: flex;
                .searchbox {
                    background: #ffff;
                    width: 12em;
                    border: 0;
                    padding: 3%;
                }
                .srch-icon {
                    padding: 3%;
                    border-radius: 0 6px 6px 0;
                    cursor: pointer;
                }
            }
        }
    }

    .search-social:focus-within .searchbox::placeholder {
        color: transparent;
    }
    ::placeholder {
        opacity: 0.4;
    }

    .meta-link {
        display: flex;
        grid-column: 5/7;
        grid-row: 2;
        margin-left: 10.25%;
        margin-top: -40%;
        align-items: center;
    }
    .meta-link .reg-form {
        display: block;
        text-transform: uppercase;
        font-size: clamp(0.3em, 0.8vw, 0.938em);
        border-right: solid 1px #ffff;
        padding-right: 2.832%;
    }
    .meta-link .login-form {
        display: block;
        text-transform: uppercase;
        font-size: clamp(0.3em, 0.8vw, 0.938em);
        border-left: solid 1px #ffff;
        padding-left: 2.832%;
    }
    .login-form > a,
    .reg-form > a {
        color: #ffff;
    }
    .network-links {
        display: inline-block;
        width: 55%;
        filter: brightness(1.5);
    }
    .network-links .social.social-facebook {
        background: url('img/Header-socialPhoto/icons8-facebook-48.png')
            no-repeat 20%;
        text-indent: -9999px;
        display: inline-block;
        width: 16%;
        margin-left: 5.665%;
        background-size: 100% auto;
    }
    .network-links .social.social-twitter {
        background: url('img/Header-socialPhoto/icons8-twitter-48.png')
            no-repeat 20%;
        text-indent: -9999px;
        display: inline-block;
        width: 16%;
        margin-left: 1.416%;
        background-size: 100% auto;
    }
    .network-links .social.social-instagram {
        background: url('img/Header-socialPhoto/icons8-instagram-48.png')
            no-repeat 20%;
        text-indent: -9999px;
        display: inline-block;
        width: 16%;
        margin-left: 1.416%;
        background-size: 100% auto;
    }
    .network-links .social.social-youtube {
        background: url('img/Header-socialPhoto/icons8-play-button-48.png')
            no-repeat 20%;
        text-indent: -9999px;
        display: inline-block;
        width: 16%;
        margin-left: 1.4169%;
        background-size: 100% auto;
    }
}

/*


*/
/*+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++| NAV - Desktop view |+++++++++++++++++++++++++++++++++++++++++++++++++*/
@media screen and (width > 35em) {
    /*Remove the hamburger icon from the screen on wide display*/
    .hamburgerMenu,
    .arrow {
        display: none;
    }

    /*Remove the close icon from the screen on wide display*/
    .navClose {
        display: none;
    }
    .nav-wrap {
        background-color: darkmagenta;
        width: 100%;
        height: 6.677%;
        margin: 0;
    }
    & .header-nav {
        margin: 0 auto;
        width: 80%;
        visibility: visible;
        padding: 0;
        position: static;
        background-color: #8b008b;
        display: block;
        overflow: visible;
    }
    .nav-list {
        height: 100%;
        width: 100%;
        padding: 0;
        text-align: center;
        margin: auto;
        background-color: #8b008b;
        & li {
            position: relative;
            display: inline-block;
            width: 11.5%;
            padding: 0;
            text-transform: none;
            margin: 0;
            font-size: clamp(1em, 1vw, 2em);
            & a {
                display: inline-block;
                padding: 9.8% 11.3%;
                color: white;
                font-size: clamp(1em, 1vw, 1em);
            }
            & ul {
                padding: 0;
            }
        }
    }
    #home-icon {
        display: inline-block;
        height: 100%;
        width: 100%;
        text-indent: -9999px; /* pushes away the 'Home' text off screen*/
        padding: 2.18%;
        background: url('https://img.icons8.com/small/26/ffffff/home.png')
            no-repeat 50%; /* add the url at the background image*/
        background-size: auto 100%;
    }
    .nav-list > a > #home-icon {
        width: 50px;
    }
    .dropdown-list {
        position: absolute;
        display: none;
        padding: 10px 0 10px 0;
        background-color: #d84ad8;
        width: 158%;
        text-align: justify;
        opacity: 0.85;

        & li {
            display: table;
            width: 100%;

            & a {
                display: block;
                padding: 5% 5%;
                color: rgb(0, 0, 0);
                filter: brightness(1.5);
                font-size: clamp(0.5em, 1vw, 1em);
            }
        }
    }
    .nav-list li:hover {
        background-color: rgb(116, 15, 116);
    }

    .nav-list > li > a:hover,
    .nav-list > li > a.active {
        color: #00a400;
        filter: brightness(1.5);
    }
    .nav-list > li > a:target {
        color: #00a400;
        filter: brightness(1.5);
    }

    /*++++++++++++++++++++++++| MAIN NAVIGATION MENU - HOME ICON |++++++++++++++++++++++++++++++++++*/

    #home-icon > a:focus {
        background-color: darkgreen;
    }
    #home-icon > a:hover {
        background-color: darkgreen;
    }
    #homeIcon:hover {
        background-color: transparent; /*Remove the background-color from the home icon*/
    }

    /*+++++++++++++++++++++++++++++ MAIN NAVIGATION MENU - DROPDOWN LIST |+++++++++++++++++++++++++++*/

    .nav-list > li:hover .dropdown-list {
        display: block;
        z-index: 10;
        position: absolute;
        left: 0;
    }

    .dropdown-list > li > a:hover {
        background-color: rgb(116, 15, 116, 0.8);
    }
}

/*


*/
/*++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++| TABLET VIEW |++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/

@media screen and (min-width: 35em) and (max-width: 75em) {
    .main-wrapper-1 {
        padding: 0 1.25rem;
    }
}
/*+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++|MAIN - DESKTOP VIEW |++++++++++++++++++++++++++++++++++++++++++++++++*/

/*=========================================| MAIN - ROLE PAGE-TRANSITION SECTION  (DESKTOP SCREEN ONLY)|===========================================*/

@media screen and (width > 75em) {
    .wrapper-main-cycle {
        display: block;
        position: relative;
        z-index: 1;
        & .wrapper-1 {
            display: block;
            padding: 0;
            width: auto;
            max-width: 100%;
            height: 350px;
        }
    }

    .wrapper-1::before {
        background-color: #262b2f;
        opacity: 0.51;
    }

    .leftChevronBtn,
    .rightChevronBtn {
        position: absolute;
        cursor: pointer;
        top: 35%;
        width: auto;
        margin: 0 12.58% 0 12.58%;
        color: #00a400;
        filter: brightness(1.5);
        font-weight: bold;
        font-size: clamp(1.5em, 3vw, 2.813em);
        border-radius: 0 3px 3px 0;
        padding: 0.84%;
        transition: 0.6s ease;
        z-index: 10;
    }
    .rightChevronBtn {
        right: 0;
    }
    .leftChevronBtn {
        left: 0;
    }
    .leftChevronBtn:hover,
    .rightChevronBtn:hover {
        background-color: #000000cc;
        opacity: 0.4;
    }

    .slide-wrapper {
        display: flex;
    }
    .transition-slide-1 {
        background-image: url('img/image wrapper/jp-valery-6W9G5G2WXGY-unsplash.jpg');
        background-repeat: no-repeat;
        position: relative;
        background-position: 10% 40%;
        max-width: 100%;
        padding: 5% 4%;
        background-size: 100% auto;
        width: 100%;
        height: 350px;
        margin: 0 auto;
        transition-timing-function: ease-out;
        transition-duration: 2s;
    }
    .transition-slide-2 {
        background-image: url('img/image wrapper/william-topa-7_4bU5tHRLQ-unsplash.jpg');
        background-repeat: no-repeat;
        position: relative;
        background-position: 10% 40%;
        max-width: 100%;
        padding: 5% 4%;
        background-size: 100% auto;
        width: 100%;
        height: 350px;
        margin: 0 auto;
        transition-timing-function: ease-out;
        transition-duration: 2s;
    }
    .transition-slide-3 {
        background-image: url('img/image wrapper/tinson-pen-aerodrome-our-today-feature1.jpg');
        background-repeat: no-repeat;
        position: relative;
        background-position: 10% 68%;
        max-width: 100%;
        padding: 5% 4%;
        background-size: 100% auto;
        width: 100%;
        height: 350px;
        margin: 0 auto;
        transition-timing-function: ease-out;
        transition-duration: 2s;
    }
    .slide-a-show {
        height: 2.5rem;
        display: block;
        text-align: center;
        padding: 1rem;
    }
    @-webkit-keyframes role-cycle {
        from {
            opacity: 0.4;
        }
        to {
            opacity: 1;
        }
    }
    @keyframes role-cycle {
        from {
            opacity: 0.4;
        }
        to {
            opacity: 1;
        }
    }
    .dot {
        display: inline-block;
        transition: background-color 0.6s ease;
        cursor: pointer;
    }
    .text {
        color: #f2f2f2;
        font-size: 15px;
        padding: 8px 12px;
        position: absolute;
        bottom: 8px;
        width: 80%;
        text-align: center;
    }
    .fa-minus {
        width: 35px;
        font-size: 25px !important;
        line-height: 0.5 !important;
        color: grey;
    }
    .active,
    .fa-minus:hover {
        color: #8a187b;
    }

    .space-div-box-1 {
        width: 25%;
    }
    .role-cycle-element-group {
        width: 100%;
        margin: 0;
        margin-left: 5%;
    }
    #role-cycle3 {
        margin: 7% 0 0 5%;
    }
    .role-cycle-element-group:after {
        content: '';
        display: block;
        clear: both;
    }

    .element-desrcpWrper-h1 {
        position: relative;
        color: #8a187b;
        width: 80%;
        max-width: 100%;
        margin-top: 0;
        margin: 0;
        height: auto;
    }
    .R1-font,
    .R2-font,
    .R3-font {
        font-family: 'baskervvilleregular', source_sans_proregular;
        font-size: clamp(2.5em, 2.5vw, 3.5em);
        margin: 0;
        height: 5em;
        color: aliceblue;
        filter: brightness(1.5);
        word-spacing: 0.2rem;
        font-weight: 800;
        border-block: solid;
        border-block-color: rgb(0, 164, 0);
        background-color: rgba(0, 0, 0, 0.3);
        width: 80%;
        padding-left: 10px;
    }
    .R1-font > h1,
    .R2-font > h1,
    .R3-font > h1 {
        margin: 0;
    }
    .ln-btn-hov {
        margin-top: 20px;
        transition: all 0.5s ease-out;
        width: max-content;
    }
    .ln-btn-hov:hover,
    .ln-btn-hov:focus {
        width: max-content;
        align-content: center;
        border-radius: 3px;
        background-color: #00a400;
        margin: 20px 0 5px 0;
        padding: 0px 3px;
        text-decoration-color: black;
    }
    .ln-btn-hov > a > p {
        word-spacing: 0rem;
        filter: brightness(1.5);
        color: aliceblue;
        margin: 4px;
    }
    .ln-btn-hov > a > p:hover {
        color: black;
    }
    .element-text-p {
        position: relative;
        width: 55%;
        right: 0;
        margin-top: 0.938rem;
        color: #fff;
        font-size: clamp(0.2em, 1.5vw, 1em);
    }
    .p-font {
        font-size: clamp(0.1em, 1.5vw, 1em);
    }
    .space-div-box-2 {
        width: 20%;
    }
    @media screen and (max-width: 80em) {
        .leftChevronBtn,
        .rightChevronBtn {
            margin: 0 8% 0 8%;
        }
        .space-div-box-1,
        .space-div-box-2 {
            display: none;
        }
        .role-cycle-element-group {
            width: 100%;
            margin: 0 auto;
        }
        .element-text-p {
            margin-left: 18%;
        }
        .element-desrcpWrper-h1 {
            margin-left: 15%;
        }
    }
}

/*+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++| WRAPPER - DESKTOP VIEW |++++++++++++++++++++++++++++++++++++++++++++++++*/
@media screen and (width > 35rem) {
    .main-wrapper-1 {
        margin-bottom: 30px;
        padding: 0;
        max-width: 75rem;
    }
    .column-200 {
        display: block;
        width: 16%;
        float: left;
        margin-right: 3%;
    }
    .column-250 {
        float: left;
        display: block;
        width: 30%;
    }
    .column-400 {
        width: 74%;
        float: left;
        display: block;
        margin-right: 3%;
        & .intro_title {
            font-size: 36px;
            text-align: center;
            line-height: normal;
            border: 0;
            margin: 0;
            padding: 0;
            outline: 0;
        }
        & .promo-list-item {
            display: flex;
            flex-direction: row;
            width: 50em;
        }
        & .card1-promoContent,
        .card2-promoContent,
        .card3-promoContent {
            flex: 1;
            display: grid;
            grid-template-rows: 2fr 3fr;
            grid-template-areas: 'image_loader_wrapper' 'text_loader_wrapper';
            margin: 15px;
            background: rgba(139, 0, 139, 0.65);
            border-radius: 10px;
            box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.28);
            & .image_loader_wrapper,
            .image_loader_wrapper,
            .image_loader_wrapper {
                grid-area: image_loader_wrapper;
                background-image: url('img/image wrapper/IMG-20171124-WA0003.jpg');
                background-size: cover;
                background-position: center;
                margin: 1em 1em 0 1em;
            }
            & .text_loader_wrapper,
            .text_loader_wrapper,
            .text_loader_wrapper {
                grid-area: text_loader_wrapper;
                margin: 0.5em 1em 1em 1em;
                & .text_title h3 {
                    margin-top: 0;
                }
                & .h3-title a {
                    color: aliceblue;
                    filter: brightness(1.5);
                    font-size: clamp(0.1em, 1.5vw, 1em);
                }
                & .text_teaser > p {
                    color: aliceblue;
                    filter: brightness(1.5);
                    font-size: clamp(0.1em, 1vw, 1em);
                }
            }
        }
        & .GalleryApp {
            border-top-style: solid;
            border-top-color: #00a400;
            border-top-width: 0.3em;
            border-bottom-style: solid;
            border-bottom-color: rgb(0, 164, 0);
            border-bottom-width: 0.3em;
            margin: 6em 0;
            padding-bottom: 2em;
            text-align: center;
            width: 100%;

            &.GalleryApp > iframe {
                height: 19.1em;
            }

            #GalleryApp {
                background-color: rgb(139, 0, 139, 70%);
                text-align: left;
                margin: 0.5em 0 2em 0;
                box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.28);

                & h1 {
                    margin: 0;
                    padding: 0.3em;
                    height: 2em;
                    color: aliceblue;
                }
            }
        }
    }
    .text_link a:hover {
        background-color: #00a400;
        color: black;
        filter: brightness(1.5);
        border-radius: 4px;
        padding: 2px 4px 2px 4px;
    }
    .pageBanner {
        height: 4em;
        margin: 3em 0 1em 0;
        background-color: rgb(139, 0, 139, 70%);
        box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.28);

        & h1 {
            margin: 0;
            padding: 0.3em;
            height: 2em;
            color: aliceblue;
        }
    }
    .adButtons {
        display: grid;
        grid-template-columns: repeat(2, 2fr);
        column-gap: 5rem;
        box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.18);
        border-radius: 0.625em;

        & .pageButtonsGrid {
            display: grid;
            grid-auto-rows: minmax(6em, auto);
            padding: 1em;
        }
        .adButtonstyle256,
        .adButtonstyle257,
        .adButtonstyle258 {
            border-radius: 0.625em;
            display: grid;
            grid-template-columns: 1fr 2fr;
            grid-template-areas: 'img content';
            margin-bottom: 1em;

            box-shadow: 0px 1px 10px 0px rgba(0, 0, 0, 0.18);
            :hover {
                box-shadow: 0px 1px 10px 3px rgba(0, 0, 0, 0.18);
                border-radius: 0 0.625em 0.625em 0;
            }
        }
        & .img {
            grid-area: img;
            border-radius: 0.625em 0 0 0.625em;
            background-image: url('img/image wrapper/IMG-20171124-WA0003.jpg');
            background-size: cover;
            background-position: center;
        }

        & .contentText {
            grid-area: content;
            padding: 10px;
        }
    }
    .msgForm {
        display: block;
        margin: 10px 0;
        width: 98%;

        .msgInput {
            margin: 0 0 15px 0;
            border-radius: 3px;
            border: 0;
            height: 30px;
            padding: 10px;
        }
    }
    .msgInput:focus {
        background: rgb(216, 74, 216, 40%);
        border-radius: 5px;
    }
}

/*


*/
/*===============================================================| FOOTER - Desktop View |======================================================*/
@media screen and (width > 35em) {
    .col-2-list,
    .small-screen-legal,
    .small-screen-social {
        display: none;
    }
    .footer-wraper {
        background-color: #d84ad8;
        & .fixed-wrap {
            display: grid;
            grid-template-columns: repeat(2, 2fr);
            column-gap: 2em;
            width: 80%;
            margin: 0 auto;
            background-color: #d84ad8;
            & .footer-logo {
                display: block;
                padding: 10px 20px 0;
                & img {
                    width: 100px;
                    height: 100px;
                }
            }
            & .footer-info {
                grid-column: 1;
                display: grid;
                grid-template-columns: repeat(2, 1fr);
                margin-bottom: 2em;
                & h3 {
                    display: block;
                }
                & .col-1-list {
                    display: block;
                    margin: 0;
                    grid-column: 1/2;
                    grid-row: 2/3;
                    list-style: inside square;
                    & li {
                        margin-bottom: 5px;
                    }
                }
                & .footer-network-links {
                    display: block;
                    margin: 0;
                    grid-column: 2/3;
                    grid-row: 2/3;
                    list-style: inside square;
                    .social-facebook,
                    .social-instagram,
                    .social-twitter,
                    .social-youtube {
                        text-indent: 0;
                        background-image: none;
                    }
                }
            }
            & .footer-address {
                grid-column: 2;
                grid-row: 1;
                margin-left: 10px;
                & p {
                    display: block;
                }
                & h3 {
                    margin-bottom: 0;
                    font-size: clamp(1rem, 2vw, 3rem);
                }
            }
            & #footer-location {
                display: block;
                grid-column: 2;
                grid-row: 2;
                margin-bottom: 2em;
            }
        }
    }

    /*============================================================| Sub-footer Section |======================================================= */

    .subfooter-wrap {
        display: block;
        & .subfooter-fixed-wrap {
            padding: 5px 0;
            background-color: #8b008b;
            color: #ffff;
            & .footer-legal-terms {
                text-align: left;
                margin: 0;
                & li,
                a {
                    display: inline-flex;
                    color: #fff;
                }
                & .txt-undl {
                    margin-left: 20px;
                }
            }
        }
    }
    .txt-undl:hover {
        text-decoration: underline;
    }
}
